define("incus-vastaanota/locales/en/translations", ["exports"], function (exports) {
  exports["default"] = {
    common: {
      example: "Example",
      cancel: "Cancel",
      archive: "Archive",
      traffic: "Traffic",
      webshop: "Webshop",
      back: "Back",
      error: "Error",
      ready: "Ready",
      save: "Save",
      download: "Download",
      deleteInvoice: "Delete Invoice",
      toChecker: "Start approval",
      toAccounting: "Send to bookkeeping",
      toCustomEmail: "To sender",
      addAttachment: "Add attachment",
      logout: "Logout",
      list: "Invoices",
      timeStamp: "Time",
      createInvoice: "Purchase invoice",
      createSalesInvoice: "Sales invoice",
      uploadComplete: "Upload complete",
      settings: "Settings",
      tools: "Tools",
      addresses: "Addresses",
      validationFailed: "Validation failed",
      notImplemented: "This feature is not yet available",
      shouldBeNumber: "Must be a numeric value",
      shouldBeDate: "Must be a valid date",
      pcs: "pcs",
      show: "Show",
      clear: "Clear",
      terms: "Terms of service",
      user: "User",
      pricing: "Pricing",
      username: "Username"
    },
    archive: {
      archives: 'Archives',
      clearAllFilters: 'Reset all fields',
      found: 'Found',
      freeText: 'Free text',
      loading: 'Loading archives...',
      received: 'Received',
      search: 'Search',
      sent: 'Sent',
      collect: 'PERI',
      sendToCollector: 'Lähetä perintään',
      homepage: 'Kotisivut',
      collectInvoices: 'Peri laskuja',
      selectedInvoices: 'Valitut laskut',
      collector: 'Perijä'
    },
    traffic: {
      sent: "Sent",
      received: "Received",
      results: "results",

      pitstop: {
        pitstop: "Processing",
        invoiceNumber: "Invoice Number",
        emailOrPhone: "Email or Phone number",
        receiverEmailHeading: "Insert receivers email address",
        receiverEmail: "Receiver Email",
        signerName: "Signer name",
        signerEmail: "Signer email",
        cashOut: "Cash out invoices!",

        pitstopStatus: {
          waiting: "Waiting",
          send_to_factoring: "Sending to factoring",
          waiting_signedtime: "waiting_signedtime",
          waiting_financieragreetime: "waiting_financieragreetime",
          waiting_buyeracceptance: "waiting_buyeracceptance"
        }
      },
      trafficLight: {
        sent: {
          black: "Could not fetch entries",
          green: "No known payment delay entries for receiver",
          yellow: "Receiver has payment delay entries"
        },

        received: {
          black: "Could not fetch entries",
          green: "No known payment delay entries for sender",
          yellow: "Sender has payment delay entries"
        }
      },
      tables: {
        sentTime: "Sent date",
        exitTime: "Sent date",
        receivedDate: "Received date",
        picture: "Image",
        data: "Data/XML",
        documentId: "documentId",
        invoiceNumber: "Invoice number",
        receiver: "Receiver",
        recipient: "Receiver",
        sender: "Sender",
        messageType: "Message type",
        dueDate: "Due date",
        deliveryMethod: "Delivery method",
        attachments: "Attachments",
        acknowledgment: "Acknowledgment",
        ackStatus: "Acknowledgment",
        createdDate: "Sent date",
        deliveryDate: "Delivery date",
        documentDate: "Document Date",
        bankAccount: "Bank account",
        sellerReference: "Seller reference",
        orderReference: "Order Reference",
        paymentReference: "Payment reference",
        paymentStatus: "Maksun tila",
        transferStatus: "Transfer status",
        approvalStatus: "Approval status",
        pitstopStatus: "Status",
        pitstopDelete: "Delete",
        pitstopDeleteConfirmation: "Are you sure want to remove invoice",
        pitstopTimeleft: "Time left",
        expiresDate: "Sending",
        provision: "Provision",
        sum: "Amount",
        sumWithoutProvision: "Amount without provision",
        email: "Email"
      },

      messageTypes: {
        bill: "Invoice",
        billOrSomething: "Invoice or other",
        acknowledgment: "Ack",
        sticker: "Sticker",
        billOrPayslip: "Invoice or payslip",
        factoring: "Factoring copy",
        edi: "EDI",
        unknown: "Unknown"
      },

      deliveryMethods: {
        webBill: "eInvoice",
        letter: "Letter",
        edi: "EDI",
        email: "Email",
        eBill: "e-lasku",
        directPayment: "Suoramaksu",
        webPost: "Netpost",
        scanned: "Scanned",
        unknown: "Unknown"
      },

      "true": "Yes",
      "false": "No"
    },
    session: {
      loginTitle: "Login",
      emailPlaceholder: "email",
      passwordPlaceholder: "Password",
      loginAction: "Login",
      logoutAction: "Logout",
      forgotPassword: "Forgot your password?",
      loginError: "Wrong Login/Password",
      logoutSuccess: "You have logged out.",
      registration: "Sign up",
      hallinta: "Services from hallinta.apix.fi have been moved to Laskumappi tools section",
      webshop: "Services from webshop.apix.fi have been moved to Laskumappi"
    },
    settings: {
      viewTitle: "Settings",
      contracts: "Services",
      language: "Language",
      finnish: "Suomi",
      swedish: "Svenska",
      english: "English",
      notification: "Notification email addresses",
      notificationsSaved: "Saved",
      arrivalNotification: "Notification for new invoice arrival",
      dueDateNotification: "Notification for due date (payment due within 3 days)",
      emailAddress: "Email address",
      saveNotificationSettings: "Save",
      invoiceSending: "Purchase invoice sending",
      noInvoiceSending: "No purchase invoice sending",
      selectServices: "Select services",
      oauth: {
        name: "Integration",
        added: "account has been integrated to Apix systems",
        notadded: "Integration is missing for account",
        add: "Add new integration",
        remove: "Remove intergration"
      },

      countryname: "Country",
      country: {
        finland: "Finland",
        sweden: "Sweden"
      },
      userInfo: {
        title: "Invoicing information",
        name: "Name",
        additionalName: "Additional name",
        userInfo: "User information",
        country: "Country",
        businessID: "Business ID",
        address: "Address",
        accounts: "Bank accounts",
        city: "City",
        zip: "Postal code",
        IBAN: "IBAN",
        contactPerson: "Contact person",
        phone: "Phone number",
        www: "Webpage",
        email: "Email",
        fax: "Fax",
        paymentCondition: "Payment condition (days)",
        bankAccount: "Account number",
        type: "Type",
        penaltyInterest: "Penalty interest (%)",
        invoiceLogo: "Invoice logo",
        logoSuccess: "Logo saved",
        logoDeleted: "Logo deleted",
        deleteLogo: "Delete logo",
        saveLogo: "Save logo",
        invalidLogo: "File must be in jpeg or png format and under 300kb",
        eInvoiceAddress: "Sender eInvoice address",
        oldPass: "Old password",
        newPass: "New password",
        repeatPass: "Repeat password",
        billingAddress: "Billing",
        officialAddress: "Official",
        visitingAddress: "Visiting",
        save: "Save",
        changePassword: "Change password",
        addresses: "Addresses",
        addressType: "Address type",
        changePass: "Change password",
        passwordRequimentTitle: "New password requirements",
        passwordRequiment: "The password must be at least twelve (12) characters long and must include at least one lowercase letter, one uppercase letter, a number, and a special character.",
        updatedPassword: "Password changed",
        updatePasswordFailed: "Changing password failed",
        updateWrongPass: "Old password incorrect",
        updatePasswordsNomatch: "Passwords don't match",
        updatedUser: "User information updated",
        updateUserFailed: "Updating user information failed"
      },

      LowellSEDebtCollection: {
        header: "Lowell Debt Collection service",
        sendRequest: "Request Lowell Debt Collection service",
        status: {
          WAITING: "Waiting Lowell's approval",
          READY: "Lowell Debt Collection service is active",
          UNKNOWN: "Lowell Debt Collection state is unknown"
        }
      }
    },
    tools: {
      admin: "Admin",
      addresses: {
        name: "Name",
        company: "Company name",
        eInvoiceAddress: "eInvoice address",
        operator: "Operator",
        otherOperator: "Other operator",
        Ytunnus: "Business ID",
        add: "Send",
        addedNewAddress: "Request sent",
        addNewAddress: 'If the address is not in use, you can request it <a class="instructions-link" href="https://apixmessaging.zendesk.com/hc/en-us">here</a>',
        addressQuery: "Search for eInvoice addresses with business ID",
        address: "Address",
        checkAddress: "Check if eInvoice address is in use",
        test: "Check",
        query: "Search",
        feedbackNeg: "Address is not in use",
        feedbackPos: "Address is in use",
        emailNotificationOne: "Notificate me",
        emailNotificationTwo: "by email",
        emailToNotificate: "Email",
        commentField: "Comment"
      },
      users: {
        title: "Users",
        usernameInUse: "Username is already in use",
        username: "Username",
        password: "Password",
        newPass: "New password",
        add: "Add",
        save: "Save",
        "delete": "Delete",
        edit: "Edit",
        cancel: "Cancel",
        allowSell: "Allow sales invoices",
        allowPurchase: "Allow purchase invoices",
        newUser: "New user"
      }
    },
    listview: {
      barcodePrompt: "Copy the barcode from this window",
      totals: "Totals",
      due0To3: "0-3 days",
      due4To7: "4-7 days",
      due8OrMore: "8+ days",
      paid: "Paid",
      unpaid: "Unpaid",
      viewTitle: "Purchase invoices",
      noneSelected: "You must select invoices first",
      nameFilter: "Filter by name",
      amountToPayFilter: "Filter by amount",
      detailView: "Info",
      documentDateFilter: "Search by invoice date",
      startDate: "From",
      endDate: "Until",
      dueDateFilter: "Search by due date",
      startDueDate: "From",
      endDueDate: "Until",
      clearAllDateFilters: "Clear date filters",
      approvalStatus: "Approval",
      approverEmail: "Approver email",
      approverTimeStamp: "Sent",
      approverComment: "Comment",
      approverApprovalStatus: "Approval",
      noComments: "Not commented yet",
      paymentStatusFilter: "Filter by payment status",
      trafficLights: ""
    },
    createSalesView: {
      viewTitle: "Create sales invoice",

      uploadTitle: "Upload",
      Upload: "Upload files",

      tooManyInvoices: "Too many invoices in one file",
      max: "max",

      tables: {
        fileName: "Filename",
        fileSize: "File size",
        fileType: "File type",
        Status: "Status",
        Response: "",
        Amount: "Amount"
      },

      status: {
        notsent: "",
        OK: "Sent successfully",
        ERR: "Error while sending"
      },

      response: {
        Saldo: "Saldo",
        CostInCredits: "Cost",
        AcceptedDocument: "Sent documents",
        NetworkedInvoices: "eInvoices",
        Letters: "Letters"
      }
    },
    createPurchaseView: {
      viewTitle: "Create purchase invoice"
    },
    createView: {
      selectedImage: "Selected image",
      barcodeInvalid: "Barcode invalid",
      basicInformation: "Basic Information",
      paymentRecipient: "Payment Recipient",
      bicCode: "BIC",
      products: "Products",
      taxFree: "Total without tax",
      taxAmount: "Tax Amount",
      total: "Total",
      taxBase: "tax base",
      imageField: "Invoice PDF",
      notFilled: "Please fill field ",
      totalZero: "The invoice total is zero (no products).",
      viewTitle: "Create invoice",
      pleaseUpload: "Please upload a PDF scan of the invoice",
      sendToScanning: "Send to scannnig service",
      createSellInvoice: "Manually enter sales invoice details",
      createBuyInvoice: "Manually enter purchase invoice details",
      fileUploaded: "File uploaded, please send to scanning or enter data manually.",
      invalidIBAN: "Bank account is not a valid IBAN",
      invalidField: "is not correct",
      addRowFailed: "Fill all row-fields",
      sendSuccess: "Sent successfully",
      discountPercentageInvalid: "Discount must be greater than zero",
      mustAddRows: "Please add rows",
      eInvoiceAddress: "eInvoiceAddress",
      noEinvoiceAddress: "The eInvoiceAddress wasn't found in Apix database. Can't send message.",
      noImageDefined: "Invoice image is not uploaded",
      noFactoringAgreementSelected: "Factoring agreement clause not selected"
    },
    invoice: {
      processingDate: "Processing date",
      partialAmount: "Amount",
      addPayment: "Add payment",
      paidAmount: "Paid amount",
      paymentDate: "Payment date",
      postingDate: "Posting date",
      amountToPay: "Sum",
      unpaidSum: "Unpaid Sum",
      approvalStatus: "Approval status",
      bankAccount: "Bank account",
      barcode: "Barcode",
      createdDate: "Created at",
      currency: "Currency",
      deliveryMethod: "Delivery method",
      details: "Details",
      documentDate: "Invoice date",
      documentID: "Invoice number",
      documentLink: "Data/XML",
      documentName: "Document name",
      dueDate: "Due date",
      imageName: "Image name",
      imageLink: "Image",
      invoiceChannel: "Invoice received as",
      messageType: "Message type",
      orderReference: "Order reference",
      paymentReference: "Bank reference",
      paymentStatus: "Payment status",
      receiverID: "Recipient ID",
      receiverName: "Recipient name",
      receiverVAT: "Recipient VAT",
      sellerReference: "Seller reference",
      senderName: "Invoicer",
      senderVAT: "Business ID",
      storageKey: "Storage key",
      storageStatus: "Storage status",
      invoiceStatus: "Status",
      senderMessageId: "Sender MessageId"
    },
    paymentStatus: {
      PAID: "Paid",
      NOTPAID: "Not paid",
      "": "All"
    },
    invoiceStatus: {
      NEW: "New",
      WAIT: "Wait",
      READY: "Ready"
    },
    sellInvoice: {
      giveBankReference: "Enter atleast 4 numbers to invoice number or give bankreference",
      //details
      details: "Details",
      detailsName: "Payment recipient",
      documentDate: "Invoice date",
      documentID: "Invoice number",
      invoiceSum: "Sum total",
      documentName: "Document name",
      dueDate: "Due date",
      customReference: "Order reference",
      paymentReference: "Bank reference",
      // recipient
      recipient: "Buyer",
      recipientName: "Name",
      receiverVAT: "Business ID",
      recipientStreetAddress: "Street",
      recipientPostOffice: "City",
      recipientPostCode: "Postal code",
      recipientCountry: "Country",
      // sender
      sender: "Seller",
      senderName: "Name",
      senderVAT: "Business ID",
      senderStreetAddress: "Street",
      senderPostOffice: "City",
      senderPostCode: "Postal code",
      senderCountry: "Country",

      product: "Product Name",
      numberOfItems: "PCE",
      individualPrice: "Price",
      discountPercentage: "Discount %",
      totalTaxFree: "Total",
      VAT: "VAT",
      VATPercentage: "VAT %",
      VATAmount: "VAT total",
      taxableAmount: "Taxable amount",
      total: "Total",
      taxSummary: "Tax summary",
      toBePaid: "To be paid",

      add: "Add",
      addImage: "Add invoice image",
      closePreview: "Close preview",
      "delete": "Delete",
      invalidPayementReference: "Invalid bank reference",
      pdfTitle: "Invoice",
      showPreview: "Preview PDF",

      factoring: {
        title: "Factoring",
        agreementTitle: "Factoring agreement text and used bank accounts",
        select: "Select",
        change: "Change",
        name: "Name",
        bid: "BusinessID"
      }
    },
    invoiceView: {
      upload: "Upload attachments",
      administrate: "Administrate",
      viewTitle: "Invoice",
      imageName: "Image",
      changePaymentStatus: "Change payment status",
      changeInvoiceStatus: "Change invoice status",
      paymentStatus: "payment status",
      invoiceStatus: "invoice status",
      paidTooltip: "Mark Invoice as PAID",
      notPaidTooltip: "Mark Invoice as NOT PAID",
      readyTooltip: "Mark invoice as READY for retrieval",
      waitTooltip: "Put invoice to WAIT for approval",
      newTooltip: "Set invoice as NEW"
    },
    storageView: {
      viewTitle: "Attachment",
      imageName: "Image",
      files: "Files",
      downloadAll: "Download all",
      urlError: "Error in storage URL address, please check the link and try again."
    },
    comment: {
      addComment: "Add comment",
      thumbsUp: "Yay!",
      thumbsIdle: "Meh",
      thumbsDown: "morturi te salutant",
      editComment: "Edit",
      cancel: "Cancel",
      edited: "Edited by"
    },
    email: {
      add: "Add",
      send: "Send email",
      checkerSubject: "Asiatarkista lasku",
      accountingSubject: "Ostolasku(t)",
      noRecipient: "Error, please enter one or more email recipients.",
      customEmailHeader: "Write your email here.",
      customSubject: "Email from ",
      noComments: "No comments",
      invalidRecipient: "The email address is not valid"
    },
    footer: {
      company: "Apix Messaging Oy",
      address: "Sinikalliontie 7, 02630 Espoo",
      infoGuidebook: "User's Guide",
      vatNumber: "VATID: FI23327487",
      customerService: "Customer service",
      serviceEmail: "servicedesk@apix.fi"
    },
    tooltip: {
      checker: "Invoice has been sent for inspection.",
      dueSoon: "Invoice is due very soon",
      negative: "Approval status is negative.",
      neutral: "Approval status is neutral.",
      pastDue: "Invoice is past due",
      positive: "Approval status is positive.",
      retrieved: "Invoice has been retrieved.",
      sentToBookkeeping: "Invoice has been sent to accounting.",
      wait: "Invoice is on hold."
    },
    registration: {
      common: {
        back: "Back",
        error: "Unknown error",
        registrationHeader: "Registration"
      },
      footer: {
        company: "Apix Messaging Oy",
        address: "Sinikalliontie 7, 02630 Espoo",
        infoGuidebook: "Instructions",
        businessId: "Business id: 2332748-7",
        customerService: "Customer service",
        serviceEmail: "servicedesk@apix.fi"
      },
      form: {
        acceptContract: "Approve agreement",
        addContractFailed: "Creation of agreement failed:",
        apiError: "Contact to service failed.",
        basicInfo: "Enter basic information",
        confirm: "Confirm approval of agreement",
        contractsCreated: "Agreement created succesfully.",
        country: {
          FI: "Finland",
          GB: "England",
          LV: "Latvia",
          LT: "Lithuania",
          NO: "Norway",
          PL: "Poland",
          SE: "Sweden",
          DE: "Germany",
          DK: "Denmark",
          EE: "Estonia"
        },
        createCustomerFailed: "Creation of customer relationship failed. Please contact servicedesk, error:",
        emailListPlaceholder: "email@address.fi",
        invalidBusinessId: "Business id should be a proper business id, format either FIXXXXXXXX or XXXXXXX-X",
        invalidNotifyEmail: "Additional information should be a list of email addresses, separated by a semi-colon",
        invalidUsername: "The email address has to be a real email address (eg. name@address.fi)",
        language: {
          de_DE: "German",
          en_US: "English",
          fi_FI: "Finnish",
          sv_SE: "Swedish"
        },
        missingMandatory: "Missing mandatory field:",
        parameterMandatory: "Missing mandatory additional information:",
        passwordMismatch: "The passwords do not match",
        passwordTooShort: "Password must be at least 4 characters long.",
        queryBusinessId: "Search",
        queryBusinessIdHelp: "(Format: xxxxxxx-y)",
        selectCountry: "Select country",
        selectLanguage: "Select language",
        serviceMandatory: "Either 'Send' or 'Receive' must be selected.",
        serviceSelection: "Choose service",
        softwareName: "Software",
        softwareVersion: "Version",
        usernameHelp: "(your email address is your username for accessing Apix services)",
        verifyForm: "Select service",
        verifyFormFailed: "Missing information in registration.",
        websiteHelp: "(Eg.: www.apix.fi)"
      },
      formfields: {
        businessId: "Business id",
        companyName: "Company official name",
        additionalName: "Additional name",
        streetAddress1: "Street address 1",
        streetAddress2: "Street address 2",
        postCode: "Postcode",
        postOffice: "City",
        country: "Country",
        contactPerson: "Contact person",
        phoneNumber: "Telephone",
        language: "Language",
        website: "WWW",
        username: "Email",
        password: "Password",
        passwordVerification: "Confirm password"
      },
      info: {
        firstMessage: "",
        secondMessage: "The registration to use Apix Messaging Oy's services is a two-part process.",
        thirdMessage: "First you enter your company's basic information, and after that you choose a username and password for using the services. The password must be at least 4 characters long. After this you select the services you want to use.",
        fourthMessage: "After approving the agreement the system will show a summary.",
        fifthMessage: "You can modify the selected services, change contact information or password, by loggin in to Apix portal: webshop.apix.fi. Use the username and password you created in order to login to this service.",
        linkToForm: "Start"
      },
      laskumappi: {
        firstMessage: "Phases for registering to Apix Messaging Oy's services are following:",
        secondMessage: "1. Fill in basic information about your business.",
        thirdMessage: "2. Create user.",
        fourthMessage: "3. Select the services you wish to use.",
        fifthMessage: "After registering you may login with your new user.",

        webshopMessage: "You can change your services, contact information or password",
        hinnastoMessage: "Price listing can be found",
        here: "here",
        linkToForm: "Begin",
        registrationHeader: "Registration"
      },
      modal: {
        ok: "Ok",
        cancel: "Cancel"
      },
      receipt: {
        address: "Address",
        dataError: "There was a problem transferring data.",
        info: "Basic information",
        printReceipt: "Print",
        ready: "Ready",
        serviceContract: "Serviceagreement",
        serviceContractFirstText: "This is a serviceagreement between",
        prepend: "; business-id",
        and: "and",
        serviceContractSecondText: " . Specific details of the services and agreements can be found at the following address:",
        services: "Agreement services",
        startUsingService: "You can start using the service right away.",
        here: "here"
      },
      service: {
        categories: {
          archive: "Archive all sent and received documents",
          achiveHelpText: "",
          receive: "Receive",
          receiveHelpText: "",
          send: "Send",
          sendHelpText: "Send service",
          sendToCollector: "Send invoices to collector from archive",
          storagefilesystem: "Archive files. Price 0,84 stamps per file (max. 50mb)"
        },
        receive: {
          arrivalNotification: "Notification about new received invoices",
          arrivalNotificationHelpText: "",
          expiryNotification: "Notification about invoices reaching their due date",
          expiryNotificationHelpText: "",
          edi: "I would like to receive EDI messages, please contact me.",
          ediHelpText: "",
          scanning: "Scanning",
          scanningHelpText: "",
          emailScan: "Email-pdf scanning, activation takes 2-3 working days.",
          emailScanHelpText: ""
        },
        send: {
          priority: "Service for sending paper invoices in priority class, instead of 2nd class",
          priorityHelpText: "",
          consumerInvoicing: "Consumer einvoicing, please contact me.",
          consumerInvoicingHelpText: "",
          edi: "I would like to send EDI messages, please contact me.",
          ediHelpText: "",
          accounting: "Service for sending an accounting copy to the accountatn via email. Cost 0,15 stamps/transaction",
          accountingHelpText: "",
          duplicateCheck: "Service that prevents the same invoice to be sent multiple times",
          duplicateCheckHelpText: "",
          debtCollect: "Payment service, please contact me.",
          debtCollectHelpText: "",
          factoringCopy: "Invoice financing, please contact me.",
          factoringCopyHelpText: "",
          factoringCopyAll: "Factoring copy of all invoices, please contact me.",
          factoringCopyAllHelpText: ""
        }
      },
      softwareNames: {
        other: "Other"
      }
    },
    pricing: {
      services: "Services",
      stamps: "Webstamp"
    },
    passwordreset: {
      send: "Send",
      description: "An email with link to reset password will be sent to you.",
      invalidlink: "Link is invalid.",
      invalidPasswords: "Passwords do not match or they are under 4 characters long",
      here: "Request for new link",
      sent: "Email has been sent",
      min4chars: "- minimum 4 characters"
    }
  };
});