define("incus-vastaanota/locales/sv/translations", ["exports"], function (exports) {
  exports["default"] = {
    common: {
      example: "Exempel",
      traffic: "Transaktioner",
      cancel: "Avbryt",
      webshop: "Nätbutik",
      archive: "Arkiv",
      back: "Tillbaka",
      error: "Fel",
      ready: "Färdig",
      save: "Spara",
      download: "Ladda ner",
      deleteInvoice: "Radera faktura",
      toChecker: "Skicka till granskning",
      toAccounting: "Skicka till bokföring",
      toCustomEmail: "Skicka till sändaren",
      addAttachment: "Lägg till bilaga",
      logout: "Logga ut",
      list: "Fakturor",
      timeStamp: "Tid",
      createInvoice: "Inköpsfaktura",
      createSalesInvoice: "Kundfaktura",
      uploadComplete: "Uppladdningen färdig",
      settings: "Inställningar",
      tools: "Verktyg",
      addresses: "Adresser",
      validationFailed: "valideringen misslyckades",
      notImplemented: "Otillgänglig",
      username: "Användarnamn",
      pcs: "st",
      show: "Visa",
      clear: "Rensa",
      terms: "Villkor",
      user: "Huvudanvändare",
      pricing: "Priser"
    },
    traffic: {
      sent: "Skickade",
      received: "Mottagna",
      results: "träffar",

      pitstop: {
        pitstop: "Behandlas",
        invoiceNumber: "Fakturanummer",
        emailOrPhone: "Email or Phone number", /* <------------------------- */
        receiverEmailHeading: "Insert receivers email address", /* <------------------------- */
        receiverEmail: "Receiver Email", /* <------------------------- */
        signerName: "Signer name", /* <------------------------- */
        signerEmail: "Signer email", /* <------------------------- */
        cashOut: "Sälj out fakturorna",

        pitstopStatus: {
          waiting: "Waiting", /* <------------------------- */
          send_to_factoring: "Sent to factoring", /* <------------------------- */
          waiting_signedtime: "waiting_signedtime", /* <------------------------- */
          waiting_financieragreetime: "waiting_financieragreetime", /* <------------------------- */
          waiting_buyeracceptance: "waiting_buyeracceptance" /* <------------------------- */
        }
      },

      tables: {
        sentTime: "Skickat",
        exitTime: "Skickat",
        receivedDate: "Mottaget",
        picture: "Bild",
        data: "Data/XML",
        documentId: "Dokument-ID",
        invoiceNumber: "Dokument-ID",
        receiver: "Mottagare",
        recipient: "Mottagare",
        sender: "Avsändare",
        messageType: "Meddelandetyp",
        dueDate: "Förfallodatum",
        deliveryMethod: "Leveransmetod",
        attachments: "Bilagor",
        acknowledgment: "Kvittering",
        ackStatus: "Kvittering",
        createdDate: "Skapad, datum",
        deliveryDate: "Mottagen, datum",
        documentDate: "Dokumentdatum",
        bankAccount: "Kontonummer",
        sellerReference: "Säljarens referens",
        orderReference: "Beställningsreferens",
        paymentReference: "Betalningsreferens",
        paymentStatus: "Betalningsstatus",
        transferStatus: "Transferstatus",
        approvalStatus: "Godkännandestatus",
        pitstopStatus: "Status",
        pitstopDelete: "Ta bort",
        pitstopDeleteConfirmation: "Är du säker på att du vill ta bort fakturan",
        pitstopTimeleft: "Återstående tid",
        expiresDate: "Skickas",
        provision: "Provision",
        sum: "Summa",
        sumWithoutProvision: "Summa utan provision",
        email: "Email"
      },

      messageTypes: {
        bill: "Faktura",
        billOrSomething: "Faktura eller annan",
        acknowledgment: "Bekr",
        sticker: "Sticker",
        billOrPayslip: "Faktura eller lönebesked",
        factoring: "Factoring-kopia",
        edi: "EDI",
        unknown: "Okänd"
      },

      deliveryMethods: {
        webBill: "e-Faktura",
        letter: "Brev",
        edi: "EDI",
        email: "Email",
        eBill: "e-Faktura",
        directPayment: "Direktbetalning",
        webPost: "Netpost",
        scanned: "Scannad",
        unknown: "Okänd"
      },

      "true": "Ja",
      "false": "Nej"
    },
    archive: {
      archives: 'Arkiv',
      clearAllFilters: 'Töm alla fält',
      found: 'Hittades',
      freeText: 'Fritext',
      loading: 'Förbereder arkiv...',
      received: 'Mottagna',
      search: 'Sök',
      sent: 'Skickade',
      collect: 'PERI',
      sendToCollector: 'Lähetä perintään',
      homepage: 'Kotisivut',
      collectInvoices: 'Peri laskuja',
      selectedInvoices: 'Valitut laskut',
      collector: 'Perijä'
    },
    session: {
      loginTitle: "Logga in",
      emailPlaceholder: "email",
      passwordPlaceholder: "Lösenord",
      loginAction: "Logga in",
      logoutAction: "Logga ut",
      forgotPassword: "Glömt lösenord ?",
      loginError: "Fel användarnamn/lösenord",
      logoutSuccess: "Du har nu loggat ut.",
      registration: "Registrera Dig",
      hallinta: "Services from hallinta.apix.fi have been moved to Laskumappi tools section",
      webshop: "Services from webshop.apix.fi have been moved to Laskumappi"
    },
    settings: {
      viewTitle: "Inställningar",
      contracts: "Tjänster",
      language: "Språk",
      finnish: "Suomi",
      swedish: "Svenska",
      english: "English",
      notificationsSaved: "Sparad",
      notification: "Email för notifieringar",
      arrivalNotification: "Meddelande om mottagna fakturor",
      dueDateNotification: "Meddelande om kommande förfallodatum (förfallodatum inom 3 dagar)",
      emailAddress: "Email",
      saveNotificationSettings: "Spara",
      invoiceSending: "Purchase invoice sending",
      noInvoiceSending: "No purchase invoice sending",
      selectServices: "Välj tjänster",
      countryname: "Land",
      country: {
        finland: "Finland",
        sweden: "Sverige"
      },
      userInfo: {
        title: "e-faktura information",
        name: "Namn",
        additionalName: "Ytterligare namn",
        userInfo: "User information",
        country: "Land",
        businessID: "Orgnummer",
        address: "Adress",
        accounts: "Konton",
        city: "Stad",
        zip: "Postnummer",
        IBAN: "IBAN",
        contactPerson: "Kontaktperson",
        phone: "Telefon",
        www: "Webbsida",
        email: "Email adress",
        fax: "Fax",
        paymentCondition: "Betalningsvillkor (dagar)",
        bankAccount: "Kontonummer",
        type: "Typ",
        penaltyInterest: "Förseningsavgift (%)",
        invoiceLogo: "Logo på fakturabilden",
        logoSuccess: "Loggan sparad",
        logoDeleted: "Loggan raderad",
        deleteLogo: "Radera logo",
        saveLogo: "Spara logo",
        invalidLogo: "Filen måste vara i jpeg- eller png-format, och under 300kb",
        eInvoiceAddress: "Avsändarens e-fakturaadress",
        oldPass: "Gammalt lösenord",
        newPass: "Nytt lösenord",
        repeatPass: "Upprepa nya lösenordet",
        billingAddress: "Fakturering",
        officialAddress: "Officiell adress",
        visitingAddress: "Besöksadress",
        save: "Spara",
        changePassword: "Ändra lösenordet",
        addresses: "Adresser",
        addressType: "Adresstyp",
        changePass: "Ändra lösenordet",
        passwordRequimentTitle: "Nya lösenordskrav",
        passwordRequiment: "Lösenordet måste vara minst tolv (12) tecken långt och innehålla minst en gemen bokstav, en versal bokstav, en siffra och ett specialtecken.",
        updatedPassword: "Lösenordet ändrat",
        updatePasswordFailed: "Lösenordet kunde inte ändras ",
        updateWrongPass: "Det gamla lösenordet är felaktigt ",
        updatePasswordsNomatch: "Lösenordet matchar inte ",
        updatedUser: "Användarinformationen har sparats",
        updateUserFailed: "Uppdateringen av användarinformationen misslyckades"
      },
      LowellSEDebtCollection: {
        header: "Lowells inkassotjänst",
        sendRequest: "Ansök om Lowells inkassotjänst",
        status: {
          WAITING: "Väntar på Lowells godkännande",
          READY: "Lowells inkassotjänst är aktiverad",
          UNKNOWN: "Statusen på Lowells inkassotjänst är okänd"
        }
      }
    },
    tools: {
      admin: "Admin",
      addresses: {
        company: "Företagets namn",
        name: "Namn",
        eInvoiceAddress: "E-fakturaadress",
        operator: "Operatör",
        otherOperator: "Annan operatör",
        Ytunnus: "Orgnummer",
        add: "Sänd",
        addedNewAddress: "Meddelande skickat",
        addNewAddress: 'Om adressen inte är i användning kan du begära den <a class="instructions-link" href="https://apixmessaging.zendesk.com/hc/en-us">här</a>',
        checkAddress: "Kontrollera om e-fakturaadressen är i användning",
        addressQuery: "Hämta e-fakturaadresser med företagets organisationsnummer",
        test: "Kontrollera",
        query: "Sök",
        feedbackNeg: "Adressen är inte i användning",
        feedbackPos: "Adressen är redan använd",
        emailNotificationOne: "Jag vill få en bekräftelse på att",
        emailNotificationTwo: "adressen är tillagd via email",
        emailToNotificate: "Email",
        commentField: "Tilläggsinformation"
      },
      users: {
        title: "Ytterligare användare",
        usernameInUse: "Användarnamnet används redan",
        username: "Användarnamn",
        password: "Lösenord",
        newPass: "Nytt lösenord",
        add: "Lägg till",
        save: "Spara",
        "delete": "Radera",
        edit: "Redigera",
        cancel: "Avbryt",
        allowSell: "Tillåt skapande av försäljningsfakturor",
        allowPurchase: "Tillåt skapande av inköpsfakturor",
        newUser: "Ny användare"
      }
    },
    listview: {
      barcodePrompt: "Kopiera streckkoden från rutan nedan",
      totals: "Totalt",
      due0To3: "0-3 dagar",
      due4To7: "4-7 dagar",
      due8OrMore: "8+ dagar",
      paid: "Betalad",
      unpaid: "Obetalad",
      viewTitle: "Leverantörsfakturor",
      noneSelected: "Du måste välja fakturor först",
      nameFilter: "Filtrera enligt namn",
      amountToPayFilter: "Filtrera enligt summa",
      detailView: "Info",
      documentDateFilter: "Sök enligt fakturadatum",
      startDate: "Fr.o.m",
      endDate: "Till",
      dueDateFilter: "Sök enligt förfallodatum",
      startDueDate: "Fr.o.m",
      endDueDate: "Till",
      clearAllDateFilters: "Töm datumfilter",
      approvalStatus: "Granskning",
      approverEmail: "Granskarens email",
      approverTimeStamp: "Skickad",
      approverComment: "Kommentar",
      approverApprovalStatus: "Godkännande",
      noComments: "Inga kommentarer ännu",
      paymentStatusFilter: "Filtrera enligt status på betalning",
      trafficLights: ""
    },
    createSalesView: {
      viewTitle: "Skapa kundfaktura",

      uploadTitle: "Ladda upp",
      Upload: "Ladda upp filer",
      tooManyInvoices: "För många fakturor i en fil",
      max: "max",

      tables: {
        fileName: "Filnamn",
        fileSize: "Filstorlek",
        fileType: "Filtyp",
        Status: "Status",
        Response: "",
        Amount: "Summa" /* <------------------------- */
      },

      status: {
        notsent: "",
        OK: "Sent successfully", /* <------------------------- */
        ERR: "Error while sending" /* <------------------------- */
      },

      response: {
        /* <------------------------- */
        Saldo: "Saldo", /* <------------------------- */
        CostInCredits: "Cost", /* <------------------------- */
        AcceptedDocument: "Sent documents", /* <------------------------- */
        NetworkedInvoices: "eInvoices", /* <------------------------- */
        Letters: "Letters" /* <------------------------- */
      }
    },
    createPurchaseView: {
      viewTitle: "Skapa inköpsfaktura"
    },
    createView: {
      selectedImage: "Vald PDF",
      barcodeInvalid: "Streckkoden är inte korrekt",
      basicInformation: "Grundinformation",
      paymentRecipient: "Betalningsmottagare",
      bicCode: "BIC",
      products: "Produkter",
      taxFree: "Totalt utan skatt",
      taxAmount: "Skatt",
      total: "Totalt",
      taxBase: "Skattesats",
      imageField: "PDF-Faktura",
      notFilled: "Fyll i fält ",
      totalZero: "Fakturans summa är noll (inga produkter).",
      viewTitle: "Skapa faktura",
      pleaseUpload: "Ladda upp en inskannad bild i PDF-format",
      sendToScanning: "Skicka PDF till skanningstjänsten",
      createSellInvoice: "Mata in fakturans information för hand",
      createBuyInvoice: "Mata in fakturans information för hand",
      fileUploaded: "Filen inläst, skicka PDF till skanningstjänsten eller mata in informationen",
      invalidIBAN: "Kontonummer måste vara et IBAN",
      invalidField: "är fel",
      addRowFailed: "Måste fylla alla i nytt rad",
      sendSuccess: "Skickad",
      discountPercentageInvalid: "Rabatt måste vara större än noll",
      mustAddRows: "Åtminstone en rad måste fyllas i",
      eInvoiceAddress: "eFaktura-adress",
      noEinvoiceAddress: "eFaktura-adressen finns inte i Apix databas. Fakturan kan inte skickas",
      noImageDefined: "Invoice image is not uploaded", /* <------------------------- */
      noFactoringAgreementSelected: "Factoring agreement clause not selected" /* <------------------------- */
    },
    invoice: {
      processingDate: "Behandlingsdatum",
      partialAmount: "Belopp",
      addPayment: "Lägg till betalning",
      paidAmount: "Betalat belopp",
      paymentDate: "Betalningsdatum",
      postingDate: "Bokningsdatum",
      unpaidAmount: "Obetalt",
      unpaidSum: "Obetalt",
      amountToPay: "Summa", /* <------------------------- */
      approvalStatus: "Status på granskningen",
      bankAccount: "Kontonummer",
      barcode: "Streckkod",
      createdDate: "Skapad",
      currency: "Valuta",
      deliveryMethod: "Leveransmetod",
      details: "Uppgifter",
      documentDate: "Fakturadatum",
      documentID: "Fakturanummer",
      documentLink: "Data/XML",
      documentName: "Dokumentnamn",
      dueDate: "Förfallodatum",
      imageName: "Bildnamn",
      imageLink: "Bild",
      invoiceChannel: "Leveransmetod",
      messageType: "Meddelandets art",
      orderReference: "Beställningsreferens",
      paymentReference: "Betalningsreferens",
      paymentStatus: "Status på betalning",
      receiverID: "Mottagarens id",
      receiverName: "Mottagarens namn",
      receiverVAT: "Mottagarens Orgnummer",
      sellerReference: "Säljarens referens",
      senderName: "Fakturerare",
      senderVAT: "Orgnummer",
      storageKey: "Storage key",
      storageStatus: "Storage status",
      invoiceStatus: "Status",
      senderMessageId: "Avsändar-ID"
    },
    paymentStatus: {
      PAID: "Betalad",
      NOTPAID: "Obetalad",
      "": "Alla"
    },
    invoiceStatus: {
      NEW: "Ny",
      WAIT: "Vänta",
      READY: "Färdig"
    },
    sellInvoice: {
      giveBankReference: "Ange minst 4 siffror för fakturans nummer eller ange betalningsreferen",
      //details
      details: "Tilläggsinformation",
      detailsName: "Betalningsmottagare",
      documentDate: "Fakturadatum",
      documentID: "Fakturanummer",
      invoiceSum: "Totalt",
      documentName: "Dokumentnamn",
      dueDate: "Förfallodatum",
      customReference: "Beställningsreferens",
      paymentReference: "Betalningsreferens",
      // recipient
      recipient: "Köpare",
      recipientName: "Namn",
      receiverVAT: "Orgnummer",
      recipientStreetAddress: "Gatuadress",
      recipientPostOffice: "Ort",
      recipientPostCode: "Postnummer",
      recipientCountry: "Land",
      // sender
      sender: "Säljäre",
      senderName: "Namn",
      senderVAT: "Orgnummer",
      senderStreetAddress: "Gatuadress",
      senderPostOffice: "Ort",
      senderPostCode: "Postkod",
      senderCountry: "Land",

      product: "Produkt",
      numberOfItems: "st",
      individualPrice: "pris",
      discountPercentage: "Rabatt %",
      totalTaxFree: "Totalt",
      VAT: "Moms",
      VATPercentage: "Moms %",
      VATAmount: "Moms totalt",
      taxableAmount: "Beskattningsbart belopp",
      total: "Totalt",
      taxSummary: "Momsspecifikation",
      toBePaid: "Att betala",

      add: "Lägg till",
      addImage: "Lägg till fakturabild",
      closePreview: "Stäng förhandsvisning",
      "delete": "Radera",
      invalidPayementReference: "Felaktig betalningsreferens",
      pdfTitle: "Faktura",
      showPreview: "Förhandsvisning(PDF)",

      factoring: {
        title: "Factoring", /* <------------------------- */
        agreementTitle: "Factoring agreement text and used bank accounts", /* <------------------------- */
        select: "Select", /* <------------------------- */
        change: "Change", /* <------------------------- */
        name: "Namn",
        bid: "BusinessID" /* <------------------------- */
      }
    },
    invoiceView: {
      upload: "Ladda upp bilagor",
      administrate: "Administration",
      viewTitle: "Faktura",
      imageName: "Bild",
      changePaymentStatus: "Ändra status på betalning",
      changeInvoiceStatus: "Ändra status på faktura",
      paymentStatus: "Status på betalning",
      invoiceStatus: "Status på faktura",
      paidTooltip: "Markera fakturan betald(PAID)",
      notPaidTooltip: "Markera fakturan obetald(NOT PAID)",
      readyTooltip: "Frigör fakturan för hämtning(READY)",
      waitTooltip: "Stanna fakturan för granskning",
      newTooltip: "Markera fakturan som ny(NEW)"
    },
    storageView: {
      viewTitle: "Attachment", /* <------------------------- */
      imageName: "Image", /* <------------------------- */
      files: "Files", /* <------------------------- */
      downloadAll: "Download all", /* <------------------------- */
      urlError: "Fel i URL-adress, kontrollera länken och försök igen"
    },
    comment: {
      addComment: "Lägg till kommentar",
      thumbsUp: "Yay!",
      thumbsIdle: "Meh",
      thumbsDown: "morturi te salutant",
      editComment: "Redigera",
      cancel: "Avbryt",
      edited: "Redigerad av"
    },
    email: {
      add: "Lägg till",
      send: "Skicka email",
      checkerSubject: "Granska faktura",
      accountingSubject: "Leverantörsfaktura",
      noRecipient: "Fel, lägg till minst en mottagare.",
      customEmailHeader: "Email",
      customSubject: "Email från ",
      noComments: "Inga kommentarer",
      invalidRecipient: "Email-adressen är inte korrekt"
    },
    footer: {
      company: "Apix Messaging AB",
      address: "Bror Nilssons gata 5, 417 55 Göteborg",
      infoGuidebook: "Bruksanvisning",
      vatNumber: "Organisationsnummer: 556964-9287",
      customerService: "Kundtjänst",
      serviceEmail: "servicedesk@apixmessaging.se"
    },
    tooltip: {
      checker: "Fakturan är skickat till granskaren",
      dueSoon: "Fakturans förfallodag är snart",
      negative: "Fakturans status är negativ",
      neutral: "Fakturans status är neutral",
      pastDue: "Fakturans förfallodag är förbi",
      positive: "Fakturans status är positiv",
      retrieved: "Fakturan är redan hämtad",
      sentToBookkeeping: "Fakturan är skickat till bokföring",
      wait: "Fakturan är i höllning"
    },
    registration: {
      common: {
        back: "Tillbaka",
        error: "Okänt fel",
        registrationHeader: "Registrering"
      },
      form: {
        acceptContract: "Registrera",
        addContractFailed: "Godkännande av avtalet misslyckades: ",
        apiError: "Kontakt till tjänsten bruten.",
        basicInfo: "Fyll i din information",
        confirm: "Bekräfta avtalet",
        contractsCreated: "Avtalet har skapats.",
        country: {
          FI: "Finland",
          GB: "England",
          LV: "Lettland",
          LT: "Litauen",
          NO: "Norge",
          PL: "Polen",
          SE: "Sverige",
          DE: "Tyskland",
          DK: "Danmark",
          EE: "Estland"
        },
        createCustomerFailed: "Skapandet av kundförhållande misslyckades, vänligen ontakta serivcedesk. Fel:",
        emailListPlaceholder: "epost@address.fi",
        invalidBusinessId: "Organisationsnumret bör vara ett riktigt organisationsnummer, i formatet NNNNNN-NNNN.",
        invalidNotifyEmail: "Tilläggsinformationen bör vara en lista med epost-adresser, separerade med semikolon",
        invalidUsername: "Epost adressen måste vara en riktig epost-adress.(ex. namn@firma.fi)",
        language: {
          de_DE: "Tyska",
          en_US: "Engelska",
          fi_FI: "Finska",
          sv_SE: "Svenska"
        },
        missingMandatory: "Obligatoriskt fält saknas:",
        parameterMandatory: "Obligatorisk tilläggsinformation saknas:",
        passwordMismatch: "Lösenorden stämmer inte överens",
        passwordTooShort: "Lösenordet måste vara minst 4 tecken långt.",
        queryBusinessId: "Sök",
        queryBusinessIdHelp: "(format: xxxxxx-xxxx)",
        selectCountry: "Välj land",
        selectLanguage: "Välj språk",
        serviceMandatory: 'Endera "Skicka" eller "Mottag" måste vara valda.',
        serviceSelection: "Välj tjänster",
        softwareName: "Faktureringsprogram",
        softwareVersion: "Mjukvaruversion",
        usernameHelp: "(epostadressen är ditt användarnamn för att administrera Apix tjänster)",
        verifyForm: "Välj tjänster",
        verifyFormFailed: "Det saknas information i registreringsuppgifterna.",
        websiteHelp: "(Ex.: www.apixmessaging.se)"
      },
      formfields: {
        businessId: "Orgnummer",
        companyName: "Företagets officiella namn",
        additionalName: "Alternativa namn",
        streetAddress1: "Gatuadress 1",
        streetAddress2: "Gatuadress 2",
        postCode: "Postnummer",
        postOffice: "Postort",
        country: "Land",
        contactPerson: "Kontaktperson",
        phoneNumber: "Telefon",
        language: "Språk",
        website: "WWW",
        username: "Epost",
        password: "Lösenord",
        passwordVerification: "Bekräfta lösenordet"
      },
      info: {
        firstMessage: "",
        secondMessage: "Registreringen för att använda Apix Messaging Oy's tjänster sker i två skeden.",
        thirdMessage: "Först fyller du i information om företaget och sedan väljer du användarnamn för administrering av tjänsterna. Välj också ett lösenord till ditt användarnamn som är minst 4 tecken långt. Efter detta väljer du de tjänster som skall användas.",
        fourthMessage: "Systemet visar ett sammandrag efter att du godkännt avtalet.",
        fifthMessage: "Efter processen är slutförd kan du redigera valda tjänster såsom kontaktinformation, samt ändra lösenord på adressen: webshop.apix.fi. Använd ditt användarnamn samt lösenord du skapat för att logga in på tjänsten.",
        linkToForm: "Börja"
      },
      laskumappi: {
        firstMessage: "För att registrera dig för Apix Messaging AB:s tjänser behöver följande göras:",
        secondMessage: "1. Fyll i informationen om ditt företag.",
        thirdMessage: "2. Skapa en användare.",
        fourthMessage: "3. Välj de tjänster du önskar använda.",
        fifthMessage: "Efter registreringen kan du logga in med ditt nya användarnamn.",
        here: "här",
        webshopMessage: "Du kan ändra dina tjänster, din kontaktinformation och ditt lösenord",
        hinnastoMessage: "Vår prislista hittas",
        linkToForm: "Begin",
        registrationHeader: "Registration"
      },
      modal: {
        ok: "Ok",
        cancel: "avbryt"
      },
      receipt: {
        address: "Adress",
        dataError: "Ett fel uppstod vid transport av data.",
        info: "Basinformation",
        printReceipt: "Skriv ut",
        ready: "Färdig",
        serviceContract: "Avtal",
        serviceContractFirstText: "Detta är ett avatal mellan", // Apix Messaging Ab; orgnummer", // 2332748-7 och",
        prepend: "; orgnummer",
        and: "och",
        serviceContractSecondText: "Noggrannare beskrivningar av tjänsterna och deras villkor finns på adressen:",
        services: "Avtalets tjänster",
        startUsingService: "Du kan börja använda tjänsterna direkt.",
        here: "härifrån"
      },
      service: {
        categories: {
          archive: "Arkivera alla dokument",
          achiveHelpText: "",
          receive: "Ta emot",
          receiveHelpText: "",
          send: "Skicka",
          sendHelpText: "Skicka tjänsten",
          sendToCollector: "Skicka fakturor till inkassotjänst från arkivet",
          storagefilesystem: "Arkivera filer. Pris: 0,84 e-frimärken per fil (max. 50mb)"
        },
        receive: {
          arrivalNotification: "Notifiering av nya mottagna fakturor",
          arrivalNotificationHelpText: "",
          expiryNotification: "Notifiering om fakturor som förfaller",
          expiryNotificationHelpText: "",
          edi: "Jag vill ta emot EDI meddelanden, kontakta mig.",
          ediHelpText: "",
          scanning: "Aktivera scanning",
          scanningHelpText: "",
          emailScan: "epost-pdf skanning,aktivering tar 2-3 vardagar.",
          emailScanHelpText: ""
        },
        send: {
          priority: "Tjänst för att skicka pappersfakturor i Priority klass istället för 2. klass",
          priorityHelpText: "",
          consumerInvoicing: "Konsumentfakturering till nätbanker, kontakta mig.",
          consumerInvoicingHelpText: "",
          edi: "Jag vill skicka EDI meddelanden, kontakta mig.",
          ediHelpText: "",
          accounting: "Tjänst för att skicka bokföringskopioa till bokföraren via epost. Pris 0,15 märken/sändning",
          accountingHelpText: "",
          duplicateCheck: "Aktivera stopp för duplicerade fakturanummer",
          duplicateCheckHelpText: "",
          debtCollect: "Tjänst för Betalningsövervakning, kontakta mig.",
          debtCollectHelpText: "",
          factoringCopy: "Tjänst för fakturafinansiering, kontakta mig.",
          factoringCopyHelpText: "",
          factoringCopyAll: "Factoringkopia på alla fakturor till finansieringsboleg, kontakta mig",
          factoringCopyAllHelpText: ""
        }
      },
      softwareNames: {
        other: "Annan"
      }
    },
    pricing: {
      services: "Tjänster",
      stamps: "E-frimärken"
    },
    passwordreset: {
      send: "Skicka",
      description: "En epost med en länk för att ändra lösenordet skickas till den adress som är kopplad till kontot.",
      invalidlink: "Länken är inte längre giltig.",
      invalidPasswords: "Lösenorden är olika eller längden mindre än 4 tecken",
      here: "Begär om ny länk",
      sent: "Länken skickad",
      min4chars: "- minst 4 tecken"
    }
  };
});